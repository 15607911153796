import "./Header.scss";
import logo from "../Pictures/logos/logo-big.png";

export default function Header() {
  return (
    <div id="Header">
      <div className="container">
        <img className="logoBig" src={logo} alt="" />
        <h2>Little Terra</h2>
        <h3>Paysagisme & Permaculture</h3>
      </div>
    </div>
  );
}
