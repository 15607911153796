import DetailsRow from "./DetailsRow";
import Formation from "../Pictures/illustrations/Formation.jpeg";
import Design from "../Pictures/illustrations/design.jpeg";
import Accompagnement from "../Pictures/illustrations/accompagnement.jpeg";

import "./DetailsColumn.scss";

export default function DetailsColumn() {
  const spacing = "7em";

  return (
    <div className="DetailsColumn" id="Services">
      <h2 className="DetailsColumn__title">Les services Little Terra</h2>
      <DetailsRow
        image={Formation}
        title="Formation"
        text={
          "Une formation d’initiation à la permaculture pour" +
          " connaître les bases fondamentales et partir du bon pied !"
        }
        linkText="Réserver"
        linkUrl="https://florianeciallis.typeform.com/to/KkGkMv"
      />
      <DetailsRow
        image={Design}
        title="Design"
        text={
          "Un design aux petits oignons, conçu rien que pour toi, " +
          "adapté et adaptable à ton espace de vie."
        }
        linkText="Prendre contact"
        linkUrl="https://florianeciallis.typeform.com/to/tDZi2r"
        style={{ marginTop: spacing }}
        isTextLeft
      />
      <DetailsRow
        image={Accompagnement}
        title="Accompagnement"
        text={
          "Un accompagnement à la réalisation, une aide pour se lancer." +
          " Démarrer un compost, faire tes premiers semis, apprendre à bouturer, …"
        }
        linkText="Prendre contact"
        linkUrl="https://florianeciallis.typeform.com/to/tDZi2r"
        style={{ marginTop: spacing, marginBottom: spacing }}
      />
    </div>
  );
}
