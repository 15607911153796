import "./footer.scss";
import "../Fonts/fontello/css/fontello.css";

export default function footer() {
  return (
    <div id="footer">
      <div className="objectRow">
        <div className="object">
          <h3>Nos réseaux sociaux</h3>
          <div className="socialisation">
            <a
              href="https://www.facebook.com/Little-Terra-104705820869445"
              rel="noopener noreferrer nofollow"
              target="_blank"
            >
              <div className="icon">
                <i className="icon-facebook-squared" />
              </div>
            </a>
            <a
              href="https://www.linkedin.com/company/littleterra"
              rel="noopener noreferrer nofollow"
              target="_blank"
            >
              <div className="icon">
                <i className="icon-linkedin-circled" />
              </div>
            </a>
            <a
              href="https://www.instagram.com/littleterra.fr"
              rel="noopener noreferrer nofollow"
              target="_blank"
            >
              <div className="icon">
                <i className="icon-instagram" />
              </div>
            </a>
          </div>
        </div>
        <div className="object">
          <h3>Contacts</h3>
          <p>
            <a href="mailto:contact@littleterra.fr" className="link">
              contact@littleterra.fr
            </a>
          </p>
        </div>
      </div>
      <div className="underlined">
        <div>2019 LittleTerra with love</div>
        <div>hopefully powered by plants</div>
      </div>
    </div>
  );
}
