import React from "react";
import "./DetailsRow.scss";

export default function DetailsRow({
  image,
  title,
  text,
  linkText,
  linkUrl,
  isTextLeft,
  style,
}) {
  return (
    <div
      className={`DetailsRow ${isTextLeft && "DetailsRow--textLeft"}`}
      style={style}
    >
      <div className="DetailsRow__pictureContainer">
        <img src={image} alt={`littleterra ${title}`} />
      </div>
      <div className="DetailsRow__text">
        <h2 className="DetailsRow__title">{title}</h2>
        <p className="DetailsRow__description">{text}</p>
        <br />
        <br />
        <a
          href={linkUrl}
          target="_blank"
          rel="noreferrer"
          className="DetailsRow__link"
        >
          <i>
            {linkText} {">>"}
          </i>
        </a>
      </div>
    </div>
  );
}
