import React, { Component } from "react";
import "./SideText.scss";
import PropTypes from "prop-types";

import { Parallax, withController } from "react-scroll-parallax";
import HeadFlo from "../Pictures/illustrations/floHead.jpg";

class SideText extends Component {
  static propTypes = {
    parallaxController: PropTypes.object,
  };

  componentDidMount() {
    const { parallaxController } = this.props;
    parallaxController.update();
  }

  render() {
    return (
      <div id="About">
        <div className="background">
          <Parallax className="picture1" y={[-30, 35]}>
            <img src={HeadFlo} alt="" />
          </Parallax>
        </div>
        <div className="text">
          <h2>Hello, moi c’est Floriane !</h2>
          <p>
            Je t’aide à créer des espaces de biodiversité dans ton jardin, sur
            ton balcon, ta terrasse ou ton intérieur. Grâce à des techniques
            respectueuses de l’environnement, nous travaillerons ensemble pour
            végétaliser ton espace de vie de façon écologique.
            <br />
            <br /> La clé ? Une méthode alliant paysagisme et permaculture.
          </p>
        </div>
      </div>
    );
  }
}

export default withController(SideText);
