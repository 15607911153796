import "./Offers.scss";

import bonsai from "../Pictures/icons/bonsai.png";
import lotus from "../Pictures/icons/lotus.png";

export default function Offers() {
  return (
    <div id="Offers">
      <h2>Les offres</h2>
      <div className="blockContainer">
        <div className="block">
          <img src={lotus} alt="" />
          <div className="title">Conception</div>
          <div className="subText">Pour designer ensemble ton jardin</div>
          <div className="buttonRow">
            <a
              href="https://florianeciallis.typeform.com/to/tDZi2r"
              rel="nofollow"
            >
              <button>Faire une demande</button>
            </a>
          </div>
        </div>
        <div className="block">
          <img src={bonsai} alt="" />
          <div className="title">Formation</div>
          <div className="subText">À partir de 150€ pour 3 heures</div>
          <div className="buttonRow">
            <a
              href="https://florianeciallis.typeform.com/to/Ag2mDT"
              className="buttonRow__block"
              rel="nofollow"
            >
              <button>À offrir</button>
            </a>
            <a
              href="https://florianeciallis.typeform.com/to/KkGkMv"
              className="buttonRow__block"
              rel="nofollow"
            >
              <button>Pour moi</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
