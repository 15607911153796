import "./App.scss";
import NavBar from "./Components/NavBar";
import Header from "./Components/Header";
import SideText from "./Components/SideText";
import CenterText from "./Components/CenterText";
import Offers from "./Components/Offers";
import Footer from "./Components/footer";

import { ParallaxProvider } from "react-scroll-parallax";
import DetailsColumn from "./Components/DetailsColumn";
// import Testimonials from "./Components/Testimonials";

export default function App() {
  return (
    <ParallaxProvider>
      <div id="App">
        <NavBar />
        <Header />
        <SideText />
        <CenterText />
        <DetailsColumn />
        {/* <Testimonials
          testimonials={[
            {
              text: "Toto",
              signature: {
                name: "Titi",
                surname: "Tutu",
              },
            },
            {
              text: "Toto 2",
              signature: {
                name: "Titi",
                surname: "Tutu",
              },
            },
            {
              text: "Toto 3",
              signature: {
                name: "Titi",
                surname: "Tutu",
              },
            },
          ]}
        /> */}
        <Offers />
        <Footer />
      </div>
    </ParallaxProvider>
  );
}
