import React from "react";
import "./CenterText.scss";

export default function SideText() {
  return (
    <div id="Manisfesto">
      <div className="text">
        <h2>Pourquoi permaculture et paysagisme ?</h2>
        <p>
          Aujourd’hui le paysagisme consiste principalement à dompter le végétal
          dans l’unique but d’un rendu esthétique. Les techniques mises en place
          ne sont en rien écologiques, et le végétal n’est pensé que comme un
          objet de décoration.
          <br />
          <br />
          Mon but est de changer cette vision.
          <br />
          <br />
          La Nature est un outil formidable qui peut te nourrir, te soigner tout
          en embellissant ton cadre de vie et en protégeant l’environnement.
          Grâce à mon travail, ton jardin ne sera pas simplement beau, il sera
          également utile, à toi et à la biodiversité.
          {/*<br/>*/}
          {/*<br/>*/}
          {/*<a className="link" href="https://google.fr">En savoir plus !</a>*/}
        </p>
      </div>
    </div>
  );
}
