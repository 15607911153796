import "./NavBar.scss";
import logo from "../Pictures/logos/logo-header.png";

export default function NavBar() {
  return (
    <div id="NavBar">
      <header>
        <div className="logoColumn">
          <img src={logo} alt="" />
        </div>
        <div className="menu">
          <a href="#About" className="link">
            À propos
          </a>
          <a href="#Manisfesto" className="link">
            Manifeste
          </a>
          <a href="#Services" className="link">
            Services
          </a>
          <a href="#Offers" className="link">
            Offres
          </a>
          <a
            href="https://blog.littleterra.fr"
            className="link"
            rel="noopener noreferrer"
            target="_blank"
          >
            Blog
          </a>
        </div>
        <div className="callToAction">
          <a href="#Offers" className="link">
            Faire une demande
          </a>
        </div>
      </header>
    </div>
  );
}
